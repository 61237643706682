body {
  background-color: #e6f2f9 !important;
  font-family: "Arsenal", sans-serif;
}
.top-section {
  background-color: #000;
  color: #fff;
  height: 120px;
  display: flex;
  align-items: center;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  font-family: "Playfair Display", serif;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
}
.custom-select {
  position: relative;
  width: 128px;
  /* Adjust width as needed */
  margin: auto;
  margin-top: 10px;
}
.custom-select select,
.custom-select select option {
  color: var(--Ink-Lighter, #72777a);
  font-family: Arsenal;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
select,
input {
  width: 100%;
  padding: 10px;
  /* Adjust padding as needed */
  border: none;
  outline: none;
  background-color: transparent;
  -webkit-appearance: none;
  /* Remove default arrow in Chrome/Safari */
  -moz-appearance: none;
  /* Remove default arrow in Firefox */
  appearance: none;
  /* Remove default arrow in other browsers */
}
.dateselect {
  background-color: #fff;
}
.year-select * {
  color: #72777a;
}
.select-arrow {
  position: absolute;
  top: 8px;
  right: 24px;
  /* Adjust distance from the right edge */
  transform: translateY(-50%);
  width: 0;
  height: 0;
}
.competition-select ul,
.matchesStanding-select ul {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  gap: 10px;
  padding: 15px 20px;
  margin-bottom: 10px;
}
.competition-select ul li,
.matchesStanding-select ul li {
  list-style: none;
  color: var(--white, #fff);
  leading-trim: both;
  text-edge: cap;
  font-family: Arsenal;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.261px;
  padding: 14px 20px;
  align-items: center;
  background-color: #fff;
  color: #006eb6;
  border-radius: 40px;
}
.competition-select ul li.active,
.matchesStanding-select ul li.active {
  background-color: #006eb6;
  color: #fff;
  padding: 18px 20px;
}
input::placeholder {
  color: #525252;
  opacity: 1;
}
.dateselect {
  color: #525252;
}
select.hollselct {
  color: #525252;
}
select.hollselct option {
  color: #000;
}
select.hollselct option:first-child {
  color: #525252;
}
.matchesStanding-select {
  margin-top: 30px;
  margin-bottom: 30px;
}
.division-grid-wrapper {
  margin-top: 12px;
}
.division-grid-wrapper .division {
  padding: 0px 5px;
}
.division-grid-wrapper div .name {
  height: 90px;
  padding: 10px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  color: #006eb6;
  display: flex;
  position: relative;
}
.division-grid-wrapper .active.name {
  background-color: #006eb6;
  color: #fff;
  border-radius: 8px 8px 0px 0px;
  /* Set border-radius to 0 when the element has the 'active' class */
}
.division-grid-wrapper .active.name:after {
  content: "";
  position: absolute;
  bottom: -10px;
  /* 10px outside the element */
  width: 100%;
  /* Adjust as needed */
  height: 11px;
  /* Adjust as needed */
  background-color: #006eb6;
}
.division-grid-wrapper:hover {
  cursor: pointer;
}
.competition-select {
  margin: 0px 0px;
}
.select-holl > .container {
  margin-top: 70px;
  margin-bottom: 70px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.4);
}
.select-holl select {
  border-radius: 2px;
  background: #fff;
}
.select-holl h3 {
  color: var(--black, #000);
  font-family: "Playfair Display";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.297px;
}
.select-holl .head {
  font-family: Arsenal;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.453px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.select-holl .calicon {
  position: absolute;
  right: 12px;
  top: 14px;
}
.relative {
  position: relative;
  width: 100%;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.footer {
  margin-bottom: 34px;
}
.select-holl .container {
  padding-bottom: 30px;
}
.matchGroups {
  position: relative;
  width: 100%;
  background-color: #006eb6;
  z-index: 8888;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 80px 15px;
}
.matchGroups .group .groupheading {
  color: #fff;
  text-align: center;
  font-family: Arsenal;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.261px;
  margin-bottom: 20px;
}
.teamlist {
  columns: 1 !important;
  display: block;
  float: left;
  clear: both;
  width: 100%;
}
.teamlist {
  text-align: right;
  padding-right: 10px;
  position: absolute;
  width: 100%;
  background-color: #3aa0ed;
  color: #fff;
  top: 20px;
  z-index: 10000;
}
li {
  position: relative;
}
section.foldouts .wrap .foldout {
  margin-top: 10px;
}
.matchGroups {
  max-width: 100vw;
}
@media (max-width: 767px) {
  section.foldouts .wrap .foldout {
    margin-left: auto;
    margin-right: auto;
  }
  .backBtn {
    left: 20px !important;
  }
  section.foldouts .wrap .foldout {
    margin-top: 10px;
  }
}
section.foldouts .wrap .header {
  color: var(--white, #fff);
  position: relative;
  /* btn-des */
  cursor: pointer;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.2);
  height: 50px;
  /* white + shadow */
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  color: #fff;
  width: 100%;
}
section.foldouts .wrap .header h3 {
  font-family: Arsenal;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.261px;
  padding-top: 11px;
  padding-left: 10px;
}
section.foldouts .wrap .header .expand {
  position: absolute;
  color: #fff;
  font-size: 37px;
  font-weight: 100;
  top: -6px;
  right: 13px;
}
section.foldouts .wrap .header.active {
  border-radius: 8px 8px 0px 0px;
}
section.foldouts .wrap .hiddencontent {
  background: rgba(255, 255, 255, 0.1);
  display: none;
  padding: 0.5em;
  border-radius: 0px 0px 8px 8px;
}
section.foldouts .wrap .hiddencontent ul {
  width: 100%;
  display: inline-block;
  height: auto;
}
section.foldouts .wrap .hiddencontent ul li {
  float: right;
  display: block;
  color: #fff;
  clear: both;
}
.foldouts {
  margin-bottom: 30px;
}
.hide {
  display: none;
}
.matchlist table {
  border-collapse: collapse;
  max-width: 778px;
  position: relative;
  margin: auto;
}
.matchlist .standingsTable,
.matchlist table {
  margin-bottom: 100px;
  border-collapse: collapse;
  max-width: 778px;
  position: relative;
  margin: auto;
}
.matchlist .table tbody tr:nth-child(2n) {
  background-color: #f7f7f7;
  height: 70px;
}
.matchlist .hover:hover {
  cursor: pointer;
  opacity: 0.8;
}
.matchlist tr.notPlayed {
  /* border-top:2px solid #EC1D24;*/
}
.matchlist .pdfimg {
  width: 25px;
  height: 25px;
  display: inline;
  float: left;
  fill: red;
}
.matchlist .match-clock {
  float: left;
}
.matchlist .match-date-day {
  font-family: "League Gothic", sans-serif;
  font-size: 37px;
  color: #000;
  display: block;
  clear: both;
}
.matchlist .match-date-month {
  font-size: 15px;
  text-transform: uppercase;
  position: relative;
  height: auto;
  width: auto;
  top: -4px;
}
.matchlist .match-number {
  color: #525252;
  font-size: 13px;
  text-transform: uppercase;
  display: block;
  clear: both;
}
.matchlist .match-venue {
  color: #262626;
  font-size: 18px;
  display: block;
  clear: both;
  text-transform: uppercase;
}
.matchlist .match-clock {
  color: #000;
  font-size: 17px;
  font-weight: bold;
  float: left;
}
.matchlist .pdfimg {
  width: 15px;
  height: 15px;
  display: inline;
  float: left;
  fill: red;
  margin-left: 6px;
  margin-top: 4px;
}
.matchlist .match-team-logo img {
  width: 60px;
  height: auto;
}
.matchlist .match-score {
  font-size: 18px;
  font-weight: bold;
  display: block;
  clear: both;
}
.matchlist .match-team-logo img {
  width: 60px;
  height: auto;
}
.matchlist .table > tbody > tr > td,
.matchlist .table > tbody > tr > th,
.matchlist .table > tfoot > tr > td,
.matchlist .table > tfoot > tr > th,
.matchlist .table > thead > tr > td,
.matchlist .table > thead > tr > th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ededed;
}
.matchlist td {
  vertical-align: middle !important;
}
.matchlist th.blue {
  color: #0079c2;
  text-align: center;
  font-family: Arsenal;
  font-size: 15px;
  vertical-align: bottom !important;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  /* 113.333% */
}
.matchlist th.pd-med {
  color: #000;
  font-family: "Playfair Display";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.297px;
}
.matchlist td.center-align {
  text-align: center;
}
.hiddencontent.show {
  display: block !important;
}
section.foldouts .wrap .foldout {
  min-width: 250px;
  max-width: 400px;
}
.competition-select li:not(.active):hover {
  cursor: pointer;
}
.year-select select {
  cursor: pointer;
}
.venue-date {
  font-weight: bold;
  padding-top: 60px !important;
}
.content404 {
  padding: 200px 0px;
}
.foldouts a {
  text-decoration: none;
  color: #fff !important;
}
.tabselect:not(.active):hover {
  cursor: pointer;
}
.venue-date {
  color: #006eb6;
  font-family: Arsenal;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}
.matchOverview tr,
.matchOverview td {
  background-color: #fff !important;
}
.standingsTable {
  max-width: 770px;
  margin: auto;
}
.standingsTable th {
  color: #0079c2;
  text-align: center;
  font-family: Arsenal;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  /* 121.429% */
}
.standingsTable th:first-child {
  color: #000;
  font-family: "Playfair Display";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.297px;
  text-align: left;
}
.standingsTable tbody tr:nth-child(even) {
  background-color: #f7f7f7;
  /* Background color for even rows */
}
.standingsTable tbody tr:nth-child(odd) {
  background-color: #fff /* specify your desired background color for odd rows */;
}
.standingsTable td img {
  max-width: 52px;
  height: auto;
  margin-right: 12px;
}
.standingsTable .bold.uppercase {
  color: #000;
  font-family: Arsenal;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 117.647% */
  text-transform: uppercase;
}
.standingsTable td.center-align {
  text-align: center;
  vertical-align: middle;
}
.standingsTable td.center-align:last-child {
  font-weight: bold;
}
.venue-matches img {
  max-width: 52px;
  height: auto;
}
.venue-matches .left-align {
  text-align: left !important;
}
.venue-matches .right-align {
  text-align: right;
}
.venue-matches .center-align {
  text-align: center;
  vertical-align: middle;
}
.venue-matches .dash {
  vertical-align: middle;
  text-align: center;
}
.venue-matches .venue-league {
  text-align: center;
  vertical-align: middle;
  color: #323232;
  font-family: Arsenal;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 111.111% */
  letter-spacing: 0.8px;
}
.venue-matches .venue-clock {
  text-align: center;
  vertical-align: middle;
  color: #000;
  font-family: Arsenal;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 111.111% */
  letter-spacing: 0.8px;
  text-transform: uppercase;
}
.venue-matches .match-team-name {
  vertical-align: middle;
  color: #323232;
  text-align: right;
  font-family: Arsenal;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 20.4px */
  text-transform: uppercase;
}
.scheduleTable {
  margin-bottom: 50px;
  padding-bottom: 50px;
}
.scheduleTable .right-align {
  text-align: right;
}
.scheduleTable .center-align {
  text-align: center;
}
.scheduleTable .left-align {
  text-align: left;
}
.backBtn {
  position: absolute;
  left: 0px;
  top: 42px;
  width: 20px;
  height: 20px;
}
.kappingSlag {
  color: var(--white, #fff);
  font-family: "Playfair Display";
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  /* 161.538% */
  letter-spacing: 0.2px;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.footer {
  margin-top: 25px;
}
/* styles.css */
.loader {
  border: 8px solid #f3f3f3;
  /* Light grey */
  border-top: 8px solid #006eb6;
  /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Set full width and height */
.full-screen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  /* Semi-transparent white */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999999;
  /* Adjust this to make sure it's above other content */
}
.showMore {
  max-width: 778px;
  position: relative;
  margin: auto;
  color: var(--blue, #006eb6);
  leading-trim: both;
  text-edge: cap;
  font-family: Arsenal;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.261px;
  margin-top: 15px;
  padding-left: 15px;
}
.showMore:hover {
  cursor: pointer;
}
.table.scheduleTable:nth-of-type(2) {
  /* Your CSS styles for the second instance of scheduleTable */
  margin-top: 50px;
}
.scheduleTable thead * {
  border: none !important;
}
.scheduleTable tbody * {
  border-color: #f7f7f7 !important;
}
.scheduleTable .match-number {
  color: rgba(82, 82, 82, 0.5);
  leading-trim: both;
  text-edge: cap;
  font-family: Arsenal;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 153.846% */
  text-transform: uppercase;
  margin-top: 2px;
}
.scheduleTable .match-venue {
  color: #323232;
  leading-trim: both;
  text-edge: cap;
  font-family: Arsenal;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 22.8px */
  margin-top: 4px;
  margin-bottom: 4px;
}
.scheduleTable .match-date-day {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: "League Gothic";
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  /* 52.632% */
  letter-spacing: 0.893px;
  text-transform: uppercase;
  margin-top: 11px;
  margin-bottom: 3px;
}
.scheduleTable .match-date-month {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Arsenal;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
}
.scheduleTable .match-clock {
  color: #000;
  font-family: Arsenal;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.scheduleTable .match-team-name {
  color: #323232;
  font-family: Arsenal;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 20.4px */
  text-transform: uppercase;
}
.scheduleTable .match-score {
  color: #525252;
  text-align: center;
  font-family: Arsenal;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 133.333% */
  text-transform: uppercase;
}
.scheduleTable tbody tr,
.scheduleTable tbody td {
  height: 100px;
}
.group {
  width: 100%;
}
.kappingSlag {
  margin-top: 45px !important;
  margin-bottom: 28px !important;
}
.top-section.true {
  height: 160px;
}
.top-section .container {
  max-width: 770px;
  position: relative;
}
.select-division .container,
.select-holl .container {
  max-width: 830px;
}
.matchgroup-container {
  position: relative;
  margin-bottom: 10px;
}
.matchgroup-container .matchGroups {
  position: relative;
}
.standingsTable thead tr th,
.standingsTable tr,
.standingsTable td {
  border: none !important;
}
.justsmall {
  display: none;
}
@media only screen and (max-width: 600px) {
  .matchlist .pdfimg {
    width: 11px !important;
    height: 11px !important;
    margin-left: 3px;
    margin-top: 4px;
  }
  .react-datepicker-wrapper {
    width: calc(100% - 30px) !important;
    margin-left: 15px;
  }
  .calicon {
    right: 25px !important;
  }
  .select-arrow {
    right: 38px !important;
  }
  .backBtn {
    left: 15px;
  }
  .scheduleTable .match-date-day {
    font-size: 32px;
    margin-top: 10px;
    margin-bottom: -1px;
  }
  .scheduleTable .match-date-month {
    font-size: 12px;
  }
  .scheduleTable .match-number {
    font-size: 10px;
  }
  .scheduleTable .match-venue {
    color: #323232;
    leading-trim: both;
    text-edge: cap;
    text-transform: none;
    font-family: Arsenal;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 15.6px */
    margin-top: 0px;
  }
  .scheduleTable .match-clock {
    color: #000;
    font-family: Arsenal;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
  }
  .scheduleTable .match-team-name {
    font-size: 16px;
  }
  .matchlist .match-team-logo img {
    width: 40px;
    height: auto;
  }
  .matchlist .table > tbody > tr > td,
  .matchlist .table > tbody > tr > th,
  .matchlist .table > tfoot > tr > td,
  .matchlist .table > tfoot > tr > th,
  .matchlist .table > thead > tr > td,
  .matchlist .table > thead > tr > th {
    padding: 5px;
  }
  .pd-med {
    padding-left: 15px;
  }
  table {
    overflow-x: scroll;
  }
  .standingsTable thead th.blue {
    margin-bottom: 20px !important;
  }
  .standing-table .container {
    padding: 0px;
  }
  .standingsTable th {
    max-width: 50px;
    overflow: visible;
  }
  .standingsTable th span {
    display: inline-block;
    transform: rotate(-45deg);
    text-align: left !important;
    width: 50px;
    padding-left: 15px;
    font-size: 14px;
  }
  .standingsTable td img {
    width: 40px;
    height: auto;
  }
  .standingsTable td {
    padding: 5px;
  }
  .standingsTable th:first-child {
    font-size: 18px;
  }
  .standingsTable .bold.uppercase {
    font-size: 15px;
  }
  .standingsTable .bold.uppercase span {
    display: inline-block;
    top: 2px;
    position: relative;
  }
  .standingsTable td.blue {
    font-size: 16px;
  }
  .select-holl table {
    position: relative;
    top: -20px;
  }
  .select-holl .venue-clock {
    font-size: 13px !important;
  }
  .select-holl .venue-date {
    font-size: 16px !important;
    padding-top: 40px !important;
  }
  .select-holl .react-datepicker-wrapper {
    padding-bottom: 10px;
  }
  .select-holl .container {
    /*.react-datepicker-wrapper {
        width: calc(100% - 30px);
        margin-left: 15px;
      }*/
  }
  .select-holl .container h3,
  .select-holl .container .head {
    padding-left: 15px;
  }
  .select-holl .container .hollselct {
    width: calc(100% - 30px);
    margin-left: 15px;
    margin-bottom: 15px;
  }
  .venue-matches img {
    width: 40px;
    height: auto;
  }
  .venue-matches .venue-matches .venue-clock {
    font-size: 13px;
  }
  .venue-matches .venue-matches .match-team-name {
    color: #323232;
    text-align: right;
    font-family: Arsenal;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 15.6px */
    text-transform: uppercase;
  }
  .venue-matches .justsmall {
    display: block;
    float: left;
    color: #323232;
    leading-trim: both;
    text-edge: cap;
    font-family: Arsenal;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 153.846% */
    letter-spacing: 0.8px;
  }
  .venue-matches .venue-league {
    display: none;
    max-width: 0px;
  }
  .venue-matches .match-team-name {
    font-size: 15px !important;
  }
  .venue-matches .container {
    margin-top: 30px;
  }
  .matchGroups {
    padding: 50px 0px;
  }
}
.select-holl h3 {
  padding-top: 25px;
}
#root {
  max-width: 100%;
  position: relative;
  overflow: visible;
}
.react-datepicker-wrapper {
  position: relative;
}
@media only screen and (max-width: 692px) {
  .matchlist .match-team-logo img {
    width: 40px;
    height: auto;
  }
  .scheduleTable .match-venue {
    font-size: 16px;
    margin-top: 1px;
    margin-bottom: 1px;
  }
}
@media only screen and (max-width: 575px) {
  .standing-table {
    max-width: 100%;
    overflow: hidden;
  }
  .scheduleTable .match-venue {
    margin-top: -3px;
  }
  .scheduleTable .match-date-day {
    margin-top: 8px;
  }
  .teamlogo {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .match-team-logo {
    padding-left: 0px;
    padding-right: 0px;
  }
  .scoreline {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  /* .match-team-logo.left {
    padding-left: 0px !important;
  }
  .match-team-logo.right {
    padding-right: 0px !important;
  }*/
  .center-align.date {
    padding-right: 0px !important;
  }
}
@media only screen and (max-width: 480px) {
  .competition-select .col-12 {
    padding-right: 0px;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for Firefox */
  * {
    scrollbar-width: none;
  }
  .venue-matches > .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .standingsTable th span {
    font-size: 13px;
  }
  .standingsTable td {
    font-size: 14px;
  }
  .standingsTable .standingsTable .bold.uppercase {
    font-size: 14px !important;
  }
  .matchlist > .container {
    padding: 0px;
  }
  .matchlist {
    max-width: 100%;
    overflow: hidden;
  }
  .matchlist .match-team-name {
    word-wrap: break-word;
    max-width: 58px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    font-size: 12px;
  }
  .matchlist th.pd-med {
    font-size: 20px;
    padding-left: 15px !important;
  }
  .matchlist .match-team-name {
    font-size: 13px;
  }
  .matchlist .teamlogo {
    font-size: 14px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .competition-select ul {
    justify-content: left;
  }
}
@media only screen and (max-width: 410px) {
  .dash {
    font-size: 11px;
  }
  .venue-matches .match-team-name {
    font-size: 11px !important;
  }
  .venue-matches img {
    width: 35px;
    height: auto;
  }
  .matchlist .match-team-logo img {
    width: 35px;
    height: auto;
  }
  .scheduleTable .match-venue {
    font-size: 14px;
  }
  .scheduleTable .match-date-day {
    font-size: 26px;
    margin-top: 6px;
  }
  .scheduleTable .match-score {
    font-size: 14px;
  }
  .scheduleTable .match-team-name {
    font-size: 12px;
    padding-right: 5px;
  }
  .scheduleTable .match-team-name.away {
    padding-right: 5px !important;
  }
  .justsmall {
    font-size: 12px !important;
    line-height: 14px !important;
    margin-top: -2px;
  }
  .venue-matches .venue-clock {
    padding-top: 5px !important;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
}
.scheduleTable:last-of-type {
  margin-bottom: 40px;
}
.standing-table {
  margin-bottom: 40px;
}
.venue-matches > .container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.hover:hover {
  cursor: pointer;
}
.matchGroupName {
  font-family: Arsenal;
  font-size: 15px;
  margin-bottom: 2px;
}
.matchVenueName {
  text-transform: uppercase;
}
.justsmall .group {
  text-transform: none !important;
  font-size: 10px;
  line-height: 11px !important;
  margin-bottom: 2px;
}
.justsmall .venue {
  text-transform: none !important;
  font-size: 12px;
  display: block;
  word-break: break-word;
}
.venue-matches .pdfimg {
  width: 12px;
  height: auto;
  margin-left: 5px;
}
.footerbottom {
  background-color: #f0f7fb;
  padding: 11px;
  display: flex;
}
.footerbottom a {
  color: #006eb6;
  font-size: 12px;
  font-family: "Arsenal", sans-serif;
  text-decoration: none;
  position: relive;
}
.footerbottom img {
  margin-right: 6px;
}


.expand::before {
  content: "";
  position: absolute;
  top: -0px;
  left: -20px;
  right: -20px;
  bottom: -0px;
  background: transparent;
}

.hiddencontent {
  max-height: 0;

  overflow: hidden;
  display:block !important;
  transition: max-height 0.3s ease-in-out;
 padding:0px !important;
}

.hiddencontent.show {
  max-height: 500px; /* Adjust to a value large enough for your content */

}

.hiddencontent ul{
  padding:10px 15px;
}
